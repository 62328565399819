import * as _stream2 from "stream";
var _stream = _stream2;
try {
  if ("default" in _stream2) _stream = _stream2.default;
} catch (e) {}
import _buffer from "buffer";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var Buffer = _buffer.Buffer;
  const Transform = _stream.Transform;
  class UploadStream extends Transform {
    constructor(options) {
      super();
      this.boundary = options.boundary;
    }
    _transform(data, encoding, next) {
      let buffer = Buffer.isBuffer(data) ? data : Buffer.from(data, encoding);
      this.push(buffer);
      next();
    }
    _flush(next) {
      this.push(Buffer.from("\r\n", "ascii"));
      this.push(Buffer.from("--" + this.boundary + "--", "ascii"));
      return next();
    }
  }
  exports = UploadStream;
  return exports;
}